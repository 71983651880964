.hero {
  position: relative;

  @include media-breakpoint-up(md) {
    padding: 2rem 0;
  }

  &::before {
    content: "";
    display: block;
    height: 0;
    padding-top: 50%;
    background-size: cover, initial;
    background-repeat: no-repeat, repeat;
    background-position: center center;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 0;
      background-size: contain;
      background-position: left center, left top;
    }
  }

  &.hero-webinar {
    &::before {
      background-image: url(../images/webinar/home-section.jpg), url(../images/webinar/register_bg.jpg);
    }
  }

  &.hero-participation-in-eu {
    &::before {
      background-image: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url(../images/webinar/home_section_participation_in_europe.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &.hero-participatory-budgeting {
    &::before {
      background-image: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url(../images/webinar/home_section_participatory_budgeting.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &.hero-smart-city {
    &::before {
      background-image: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url(../images/webinar/home_section_smart_city.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .hero-content {
    position: relative;
    background: #fff;
  }
}
