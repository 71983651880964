.component {
  margin: 1.5rem 0;

  @include media-breakpoint-up(lg) {
    margin: 3rem 0;
  }

  &.anchor {
    padding-top: 80px;

    @include media-breakpoint-up(lg) {
      padding-top: 90px;
    }
  }

  &.nomargin {
    margin: 0;
  }

  img {
    width: 100%;
    margin: 1rem 0;

    @include media-breakpoint-up(md) {
      margin: 1rem;
    }
  }

  .card {
    img {
      margin: 0;
    }
  }
}

.reference-component {
  padding: 2rem 0 0;

  @include media-breakpoint-up(lg) {
    padding-bottom: 4rem;
  }

  .reference-images {
    img {
      width: 7rem;
      margin: 1rem;

      @include media-breakpoint-up(xl) {
        margin: 0 1rem;
      }
    }
  }
}

.welcome-component {
  background-color: #e4f3ff;
  margin-top: 0;
  padding: 1rem 0 2rem;

  button {
    margin: 1rem 1rem 1rem 0;

    @include media-breakpoint-up(xxl) {
      margin: 0 1rem 0 0;
    }
  }

  a {
    @include media-breakpoint-up(xxl) {
      margin: 0 0 0 1rem;
    }
  }

  .image-container {
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      padding-right: 0;
      padding-left: 2rem;
      margin-bottom: 0;
    }
  }
}

.prefooter {
  background-color: $gray-white;
  font-size: $subheading-font-size;
  text-align: center;
  padding: 4rem 0;
}
